import { Link, navigate } from "gatsby";
import { Box, Button, Card, CheckBox, Image, Text, TextInput } from "grommet";
import React, { useEffect, useState } from "react";
import service from "../../services/auth_service";
import storageService from "../../services/storage_service";
import { COLOR, FONT_SIZE } from "../../services/utils";
import StandardText from "../elements/standard_text";

const inputStyle = {
  background: "#EDEDED",
  fontSize: FONT_SIZE.medium,
  borderRadius: 10,
  border: "none",
};

const PlatformConnection = ({}) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const token = storageService.getToken();
    if (token) {
      navigate("/");
    }
    let login = storageService.getLogin();
    setUserName(login.username);
    setPassword(login.password);
  }, []);

  const login = () => {
    if (
      !username ||
      !username.includes("@") ||
      !username.includes(".") ||
      username.length < 6
    ) {
      setError("Identifiant non valid");
      return;
    }

    if (!password || password.length < 6) {
      setError("Mot de passe au moin 6 caractères");
      return;
    }
    setError("");
    setLoading(true);
    service
      .login(username, password)
      .then((res) => {
        const auth = res.data;
        storageService.saveAuth(auth);
        storageService.saveLogin({username, password})
        service.getPartner(auth.user.id, auth.jwt).then((pnRes) => {
          setLoading(false);
          if (pnRes.data.data?.length) {
            storageService.setPartner(pnRes.data.data[0]);
          }
          window.location.href = `/`;
        });
      })
      .catch((err) => {
        setLoading(false);
        setError("Mauvais email/mot de pass");
      });
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) login();
  };

  return (
    <Box
      width={"full"}
      height="100vh"
      align="center"
      justify="center"
      overflow={{ vertical: "auto" }}
      style={{ minHeight: "auto" }}
    >
      <Box
        align="center"
        gap="medium"
        style={{ minHeight: "auto" }}
        margin={{ vertical: "medium" }}
      >
        <Image
          src={"https://meor.fr/lib_GoFZnqrgTVOIKMVV/jk7bgswsy6fhu9vt.png"}
        />
        <br />

        <Card
          style={{ minHeight: "auto" }}
          round={{ size: "small" }}
          pad={{ vertical: "medium", horizontal: "large" }}
          border={{ color: COLOR.light_blue, side: "small" }}
          justify="center"
          align="center"
        >
          <Box
            width="large"
            alignSelf="center"
            justify="center"
            alignContent="center"
            gap="medium"
            style={{ minHeight: "auto" }}
          >
            <Text size="small" style={{ color: "#dc3545" }} textAlign="center">
              {error}
            </Text>
            <Box width="large" gap="medium" justify="center" align="center">
              <TextInput
                placeholder="Email "
                onChange={(event) => setUserName(event.target.value)}
                style={inputStyle}
              />
              <TextInput
                placeholder="Mot de passe "
                type="password"
                onKeyDown={handleEnter}
                onChange={(event) => setPassword(event.target.value)}
                style={inputStyle}
              />
              <Box width="large" justify="center" gap="small">
                <CheckBox
                  label={<StandardText label={"Sauvegarde mon mot de passe"} style={{ color: "#707070", fontSize: FONT_SIZE.medium }}/>}
                  checked={saved}
                  onChange={(e) => setSaved(e.target.checked)}
                />
              </Box>
              <Box width="large" align="center" justify="center" gap="small">
                <Button
                  size="xxlarge"
                  onClick={login}
                  disabled={loading}
                  label="Connexion"
                />
                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  <Text
                    style={{ color: "#707070", fontSize: FONT_SIZE.medium }}
                    textAlign="center"
                  >
                    J'ai oublié mon mot de passe
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default PlatformConnection;
