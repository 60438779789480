import {
  Box, ResponsiveContext
} from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlatformConnection from "../components/connect_hubrise";
import Layout from '../components/elements/layout';

const showMessage = (message, type) => {
  toast.error(message, type || 'info');
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Layout style={{ justifyContent: "center", alignContent: "center" }}>
          <Box width="full" justify="center" align="center" alignSelf="center"
            overflow={{ vertical: 'auto' }} style={{ minHeight: 'auto' }}
          >
            <ResponsiveContext.Consumer>
              {size =>
                <Box width="full">
                  <PlatformConnection
                  />
                </Box>
              }
            </ResponsiveContext.Consumer>

          </Box>
          <ToastContainer position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Layout>
      </BlockUi>
    )
  }
}

export default Login;
